/* GetNoaPage.css */
.noa-card {
  border-radius: 8px;
  border: 1px solid #9c27b0; /* BillersMind purple */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #f9f0ff; /* Light purple background */
}

.noa-card .MuiTypography-root {
  color: #4a148c; /* Dark purple text for headers */
}

.noa-button {
  background-color: #9c27b0;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.noa-button:hover {
  background-color: #7b1fa2;
}

.noa-card .MuiCardContent-root {
  padding: 20px;
}

.MuiTypography-root {
  font-family: 'Roboto', sans-serif;
}

.MuiTypography-h6 {
  color: #6a1b9a;
  font-weight: 600;
}

.MuiTypography-body1 {
  color: #512da8;
}
