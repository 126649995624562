.about-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: white;
}

.about-header {
  font-size: 36px;
  font-weight: bold;
  color: #e415ff;
  text-align: center;
  margin-bottom: 20px;
}

.about-description {
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 30px;
}

.about-section {
  margin-bottom: 30px;
}

.about-section-header {
  font-size: 28px;
  font-weight: bold;
  color: #004d99;
  border-bottom: 2px solid #004d99;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.about-text {
  font-size: 18px;
  line-height: 1.6;
}

.about-list {
  font-size: 18px;
  line-height: 1.6;
  padding-left: 20px;
}
