.services-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333; /* Dark text for better visibility on white background */
  background-color: white;
}

.services-header {
  font-size: 36px;
  font-weight: bold;
  color: #e415ff; /* Keeping header color consistent with the theme */
  text-align: center;
  margin-bottom: 20px;
}

.services-description {
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 30px;
}

.services-item {
  margin-bottom: 25px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.services-item-header {
  font-size: 24px;
  font-weight: bold;
  color: #004d99; /* Dark blue for headers to stand out */
  margin-bottom: 10px;
}

.services-item-text {
  font-size: 18px;
  line-height: 1.6;
  color: #555; /* Slightly dark gray for better readability */
}
