.batched-noa-card {
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 8px;
    margin: 16px 0;
}

.batched-noa-details, .benefit-period-section, .attending-md-section {
    margin-bottom: 16px;
}

.benefit-period {
    padding-left: 16px;
}



/* Ensure your custom styles have higher specificity */
.table .batched-noa-row:hover {
  background-color: #6a0dad !important; /* Purple on hover */
  color: white !important; /* Optional: changes text color to white */
}

.table .batched-noa-row {
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition */
}