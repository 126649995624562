/*CSS reset*/

.home * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}
:root{
  --bg-color: rgb(15, 15, 15);
  --second-bg-color: #161616;
  --text-color: white;
  --main-color: #e415ff;
}
html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body{
  background-color: var(--bg-color);
  color: var(--text-color);
  padding-top: 120px;
  padding-bottom: 60px;
  height: 100%;
}

.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3rem 9%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}


.footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem 9%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  text-align: center;
  color: white;
  font-size: 15px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .footer {
    padding: 0.5rem; /* Smaller padding for mobile */
    font-size: 0.9rem; /* Slightly smaller text size */
  }
}

.logo-container{
  display: flex;
  align-items: center;
}

.logo-container:hover{
  transform: scale(1.1);
}


.logo{
  font-size: 3rem;
  color: var(--text-color);
  font-weight: 800;
  cursor: pointer;
  transition: 0.3s ease;
  text-wrap: nowrap;
  margin-left: 1rem;
}
#logofav {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  
}

.logo:hover{
  transform: scale(1.1);
}

.navbar a {
  font-size: 1.8rem;
  color: white;
  margin-left: 4rem;
  font-weight: 500;
  transition: 0.3s ease;
  border-bottom: 3px solid transparent;
}


.navbar a:hover{
  color: skyblue;
  border-bottom: 3px solid var(--main-color);
}

.navbar a.active{
  color: var(--main-color) !important;
  border-bottom: 3px solid var(--main-color);
}

#menu-icon {
  font-size: 3.6rem;
  color: var(--main-color);
  display: none;
}

.home{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;
  margin-left: 4rem;
  margin-right: 4rem;
}
.home-content h3{
  font-size: 3.2rem;
  font-weight: 700;
}

/* Responsive Design for smaller screens */
@media (max-width: 768px) {
  .home {
    flex-direction: column; /* Stack columns vertically */
    gap: 2rem; /* Reduce the gap for mobile */
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .home-content h3 {
    font-size: 2rem; /* Reduce the font size for mobile */
  }
}

span{
  color: var(--main-color);
}
.logo span{
  color: var(--main-color);
}
.home-content h3:nth-of-type(2){
  margin-bottom: 2rem;
  font-size: 5rem;
}
.home-content h1{
  font-size: 8rem;
  font-weight: 700;
  line-height: 1.3;
}
/*.home-img{
  border-radius: 450%;
}*/
.home-img img{
  position: relative;
  width: 32vw;
  border-radius: 50px;
  box-shadow: 0 0 25px var(--main-color);
  cursor: pointer;
}
.home-content p{
  font-size: 1.8rem;
  font-weight: 500;
}
.social-icons a{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: transparent;
  border: 0.2rem solid var(--main-color);
  font-size: 2rem;
  border-radius: 50%;
  color: var(--main-color);
  margin: 3rem 1.5rem 3rem 0;
  transition: 0.3s ease;
}
.social-icons a:hover{
  color: white;
  transform: scale(1.3)translateY(-5px);
  background-color: var(--main-color);
  box-shadow: 0 0 25px var(--main-color);
}


.btncontact-container {
  display: flex;
  justify-content: center;  /* Center horizontally */
  align-items: center;      /* Center vertically */
}

.btncontact{
  display: inline-block;
  padding: 1rem 2.8rem;
  background: var(--bg-color);
  border-radius: 4rem;
  font-size: 1.6rem;
  color: var(--main-color);
  border: 2px solid var(--main-color);
  letter-spacing: 0.1rem;
  font-weight: 600;
  transition: 0.3s ease;
  cursor: pointer;
}
.btncontact:hover{
  transform: scale(1.05);
  background: var(--main-color);
  color: var(--text-color);
  box-shadow: 0 0 25px var(--main-color);
}

.text-animation{
  font-size: 34px;
  font-weight: 600;
  min-width: 280px;
}
.text-animation span{
  position: relative;
}
.text-animation span::before{
  content: 'Medical Biller';
  color: var(--main-color);
  animation: words 20s infinite;
}
.text-animation span::after{
  content: '';
  background-color: var(--bg-color);
  position: absolute;
  width: calc(100% + 8px);
  height: 100%;
  border-left: 3px solid var(--bg-color);
  right: -8px;
  animation: cursor 0.6s infinite, typing 20s steps(14) infinite;
}
@keyframes cursor{
  to{
    border-left: 2px solid var(--main-color);
  }
}
@keyframes words{
  0%,
  20%{
    content: 'Hospice Biller'
  }
  21%,
  40%{
    content: 'HH Biller';
  }
  41%,
  60%{
    content: 'ALW Biller';
  }
  61%,
  80%{
    content: 'Oasis Coder';
  }
  81%,
  100%{
    content: 'QA Support';
  }
}
@keyframes typing{
  10%,
  15%,
  30%,
  35%,
  50%,
  55%,
  70%,
  75%,
  90%,
  95% {
    width: 0;
  }
  5%,
  20%,
  25%,
  40%,
  45%,
  60%,
  65%,
  80%,
  85% {
    width: calc(100% + 8px);
  }
}