


.UserProfile {
  background-color: slategray;
  color: black;
  padding: 10px;
}

.providerCard {
  background-color: whitesmoke;
  color: black;
  padding: 20px;
  padding-bottom: 10px;
  min-height: 80vh;
}
